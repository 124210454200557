<template>
  <div class="recruit" :style="'height:' + minHeight + 'px;overflow:scroll'">
    <header>
      <h1>寻找最亮的您</h1>
      <p>把握机会，与成功并肩</p>
    </header>
    <div class="welfare">
      <h1>入职福利</h1>
      <p>IMAGE CLOUD APPLICATION</p>
      <div class="item">
        <div v-for="(item, index) in welfareList" :key="index">
          <img :src="item.url" alt="" />
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <main>
      <div
        class="item-card"
        v-for="item in tableData"
        :key="item.id"
        @click="showContent(item.id)"
      >
        <div class="title">
          <h1>{{ item.title }}</h1>
          <p>
            {{ item.address + ' / ' + item.startTime + '至' + item.endTime }}
          </p>
          <img
            class="arrow"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
            v-if="item.showContent"
            src="../../assets/images/phone/recruit/arrow-up.png"
            alt=""
          />
          <img
            class="arrow"
            v-else
            src="../../assets/images/phone/recruit/arrow-down.png"
            alt=""
          />
        </div>
        <div v-if="item.showContent" class="content">
          <h1>工作职责</h1>
          <div class="ql-editor" v-html="item.dutyDate"></div>
          <h1>岗位要求</h1>
          <div class="ql-editor" v-html="item.claimData"></div>
        </div>
      </div>
      <div v-if="isnextPage" class="btn" @click="nextPage">
        <i class="el-icon-plus"></i> 加载更多 <i class="el-icon-plus"></i>
      </div>
      <p v-else class="btn">暂时没有更多啦</p>
    </main>
    <div class="contact">
      <p class="contact-title">联系方式</p>
      <div>
        <h1 class="phoneTitle">联系电话</h1>
        <p class="phone">028-8532 6217</p>
      </div>
      <div class="ypfs">
        <h1>应聘方式</h1>
        <p>编写邮件主题“姓名-应聘职位</p>
        <p>在截止日期前发送至 hr@scknot.com</p>
      </div>
      <p class="downForm" @click="download">点击下载岗位申请表</p>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { getRecruitInfo } from '@/api/journalism'
import Footer from '../../components/Appfooter/footerbottom.vue'
// const applicationFile =
//   'https://knot.oss-cn-hangzhou.aliyuncs.com/pdf/ApplicantForm.docx' //  阿里云下载地址
const applicationFile =
  'https://sichuan-2.zos.ctyun.cn/knot/pdf/ApplicantForm.docx' //  天翼云下载地址
export default {
  components: {
    Footer
  },
  data() {
    return {
      minHeight: 0,
      welfareList: [
        {
          url: require('../../assets/images/phone/recruit/xinzi@2x.png'),
          text: '优于同行薪资'
        },
        {
          url: require('../../assets/images/phone/recruit/jili@2x.png'),
          text: '期权激励'
        },
        {
          url: require('../../assets/images/phone/recruit/canbu@2x.png'),
          text: '餐食补助'
        },
        {
          url: require('../../assets/images/phone/recruit/tongqing@2x.png'),
          text: '通勤补助'
        },
        {
          url: require('../../assets/images/phone/recruit/nianjia@2x.png'),
          text: '带薪年假'
        }
      ],
      activeNames: ['1'],
      tableData: [],
      pageNumber: 1,
      pageSize: 3,
      isnextPage: true
    }
  },
  methods: {
    showContent(e) {
      this.tableData = this.tableData.filter((item) => {
        if (item.id === e) {
          if (item.showContent) {
            item.showContent = false
          } else {
            item.showContent = true
          }
        } else {
          item.showContent = false
        }
        return item
      })

      console.log('点击', this.tableData)
    },
    async getRecruitInfoList(e) {
      const params = {
        recruitType: '社会招聘',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
      const { code, data } = await getRecruitInfo(params)
      if (code === '0000') {
        if (e === 'next') {
          if (data.recruitInfo.length !== 0) {
            this.tableData.push(...data.recruitInfo)
            this.isnextPage = true
          } else {
            this.isnextPage = false
          }
        } else {
          this.tableData = data.recruitInfo
          this.isnextPage = true
        }

        for (var item of this.tableData) {
          item.dutyDate = item.dutyDate.replaceAll('<strong', '<b')
          item.dutyDate = item.dutyDate.replaceAll('</strong', '</b')
          item.claimData = item.claimData.replace('<strong', '<b')
          item.claimData = item.claimData.replace('</strong', '</b')
          item.showContent = false
        }

        console.log(data.recruitInfo)
      }
    },
    nextPage(e) {
      this.pageNumber = this.pageNumber + 1
      this.getRecruitInfoList('next')
    },
    download() {
      window.open(applicationFile)
    }
  },
  created() {
    this.getRecruitInfoList()
this.minHeight = this.$store.state.minHeight
    //  console.log(this.tableData)
  }
}
</script>

<style lang="less" scoped>
.recruit {
  display: inline-block;
  header {
    height: 375px;
    background: url('../../assets/images/phone/recruit/rczp@2x.png') no-repeat;
    background-size: 100% 100%;
    padding-left: 70px;
    h1 {
      margin: 0;
      font-size: 52px;
      color: #fff;
      letter-spacing: 8px;
      font-weight: normal;
      padding-top: 108px;
    }
    p {
      font-size: 26px;
      color: #fff;
      font-weight: normal;
    }
  }
  .welfare {
    // height: 709px;
    h1 {
      margin: 0;
      margin-top: 53px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
      text-align: center;
    }
    p {
      margin: 0;
      margin-top: 28px;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-align: center;
      color: #969b9e;
      opacity: 0.77;
    }
    .item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 55px;
      div {
        text-align: center;
        margin-left: 60px;
        width: 170px;
        height: 205px;
        margin-top: 55px;
        img {
          width: 132px;
          height: 132px;
        }
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #2d292e;
        }
      }
    }
  }
  main {
    background-color: #f6f5f6;
    margin: auto;
    border: 1px solid transparent;
    padding-top: 40px;
    .item-card {
      height: fit-content;
      width: 672px;
      background: #ffffff;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 20px;
      .title {
        border: none;
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 34px;
        position: relative;
        .arrow {
          position: absolute;
          top: 54px;
          right: 36px;
          width: 58px;
          height: 58px;
        }
        h1 {
          margin: 0;
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #434b52;
          line-height: 34px;
        }
        p {
          margin: 0;
          padding: 0;
          margin-top: 25px;
          font-size: 26px;
          font-family: PingFang SC;
          color: #969b9f;
        }
      }
      .content {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        h1 {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #0c2948;
        }
      }
    }
    .btn {
      margin: auto;
      margin-top: 60px;
      margin-bottom: 66px;
      width: 278px;
      height: 66px;
      line-height: 66px;
      text-align: center;
      background: #0c2948;
      border-radius: 8px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      i {
        font-size: 18px;
      }
    }
  }
  .contact {
    border: 1px solid transparent;
    .contact-title {
      margin: 0px;
      height: 81px;
      line-height: 81px;
      background: #0c2948;
      color: #fff;
      font-size: 31px;
      font-family: PingFang SC;
      padding-left: 34px;
    }
    .phoneTitle {
      margin-top: 68px;
      padding-left: 34px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #242424;
    }
    .phone {
      margin-top: 39px;
      padding-left: 34px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #969b9e;
    }
    .ypfs {
      margin-top: 75px;
      padding-left: 34px;
      h1 {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #242424;
      }
      p {
        margin-top: 41px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #969b9e;
      }
    }
    .downForm {
      padding-left: 34px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0c2948;
      margin-top: 56px;
      display: block;
      margin-bottom: 80px;
    }
  }
}
</style>
